import React from 'react';

import Icon from 'components/common/Icon';

import styles from './ProgressIcon.module.scss';

export type Props = {
  icon: string;
  current: number;
  total: number;
  strokeColor?: string;
  secondColor?: string;
  zoom?: number;
  reverse?: boolean;
  radius?: number;
  stroke?: number;
};
export default function ProgressIcon({
  icon,
  current,
  total,
  strokeColor = '#0abc71',
  secondColor = '#818CEE',
  zoom = 1,
  reverse = false,
  stroke = 3,
}: Props): JSX.Element {
  //
  const radius = 24;
  const normalizedRadius = (radius - stroke * 2) * zoom;
  const circumference = normalizedRadius * 2 * Math.PI;
  const progress =
    current > total ? 1 : reverse ? (total - current) / total : current / total;
  const strokeDashoffset = circumference - progress * circumference;
  return (
    <div
      className={styles.progressIcon}
      style={{
        width: radius * 2,
        height: radius * 2,
      }}
    >
      <div className={styles.icon}>
        <Icon name={icon} width={21 * zoom} height={21 * zoom} />
      </div>
      <svg height={radius * 2} width={radius * 2}>
        <circle
          stroke={secondColor}
          fill="transparent"
          strokeWidth={1}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke={strokeColor}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    </div>
  );
}
