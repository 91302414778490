import axios from 'axios';
import React, { useState, useRef } from 'react';
import ConfirmDialog from 'components/common/ConfirmDialog';
import ReportBox from 'components/common/ReportBox';
import Icon from 'components/common/Icon';
import useStore from 'store/timeline';
import useGlobalStore from 'store/global';

import { useOutsideClick } from 'common/utils';
import { User } from 'common/interfaces/api';
import styles from './ThreeDotsMenu.module.scss';
import SubscribingStatusModal from '../SubscribingStatusModal';
import { ableToShowSubscribingStatusModal } from 'common/utils/payment';

export type Props = {
  user: User;
};

export default function ThreeDotsMenu({ user }: Props): JSX.Element {
  const setSnackbarMessage = useGlobalStore(
    (state) => state.setSnackbarMessage
  );
  const showLogin = useGlobalStore((state) => state.showLogin);
  const currentUser = useStore((state) => state.currentUser);
  const [show, setShow] = useState(false);
  const threeDotRef = useRef();
  useOutsideClick(threeDotRef, () => {
    if (show) setShow(false);
  });
  const [showSubscribingStatusModal, setShowSubscribingStatusModal] =
    useState(false);
  const [openConfirmBlockUser, setOpenConfirmBlockUser] = useState(false);
  const [openConfirmReportUser, setOpenConfirmReportUser] = useState(false);

  const handleBlockUser = async () => {
    try {
      await axios.post(`/users/blocks`, {
        user_id: user.id,
        action: 'block',
      });
      setSnackbarMessage({
        text: 'ユーザーがブロックされました',
        type: 'success',
      });
      setOpenConfirmBlockUser(false);
    } catch (e) {
      setSnackbarMessage({
        type: 'error',
        text: 'ユーザーのブロックが失敗しました',
      });
    }
    setShow(false);
  };

  const content: JSX.Element = (
    <ul className={styles.menuWrapper}>
      <li onClick={() => setOpenConfirmBlockUser(true)}>
        <Icon name="user-off" width={24} height={24} />
        <span>ユーザーをブロックする</span>
      </li>
      <li onClick={() => setOpenConfirmReportUser(true)}>
        <Icon name="user-report" width={24} height={24} />
        <span>ユーザーをレポートする</span>
      </li>
      {ableToShowSubscribingStatusModal(user) && (
        <li onClick={() => setShowSubscribingStatusModal(true)}>
          <Icon name="priming" width={24} height={24} />
          <span>プライム登録を管理する</span>
        </li>
      )}
    </ul>
  );

  return (
    <div className={styles.threeDotsMenuWrapper} ref={threeDotRef}>
      <div
        className={styles.triggerWrapper}
        onClick={() => {
          if (!currentUser) {
            showLogin({});
            return;
          }
          setShow(!show);
        }}
      >
        <Icon name="dropdown-square" width={60} height={60} />
      </div>
      <div
        className={styles.contentWrapper}
        style={{
          display: show ? 'block' : 'none',
        }}
      >
        {content}
      </div>
      {openConfirmBlockUser && (
        <ConfirmDialog
          open={openConfirmBlockUser}
          actionCloseFnc={() => setOpenConfirmBlockUser(false)}
          actionSelectedFnc={handleBlockUser}
          title="本当にブロックしますか？"
          yesText="ブロックする"
        />
      )}
      {openConfirmReportUser && (
        <ConfirmDialog
          open={openConfirmReportUser}
          actionCloseFnc={() => setOpenConfirmReportUser(false)}
          title="ユーザーをレポートする"
          withTextBox
          textBox={
            <ReportBox
              targetUserId={user.id}
              onDialogSubmit={() => setOpenConfirmReportUser(false)}
            />
          }
        />
      )}
      {currentUser && user && showSubscribingStatusModal && (
        <SubscribingStatusModal
          creator={user}
          open={showSubscribingStatusModal}
          setOpen={setShowSubscribingStatusModal}
        />
      )}
    </div>
  );
}
