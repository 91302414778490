import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import { User } from 'common/interfaces/api';
import styles from './BioSection.module.scss';

const BioSection = ({ user }: { user: User }): JSX.Element => {
  const [isShortMode, setShortMode] = useState(true);
  const [hasShortMode, setHasShortMode] = useState(true);
  const bioRef = useCallback(
    (node: Element) => {
      setShortMode(true);
      const initShortMode = setTimeout(() => {
        if (node !== null) {
          const scrollHeight = node.scrollHeight;
          const clientHeight = node.clientHeight;
          setHasShortMode(scrollHeight > clientHeight);
        }
      }, 500);
      return () => clearTimeout(initShortMode);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user?.bio, user?.username]
  );

  if (!user?.bio) return <></>;
  return (
    <div className={styles.bioSection}>
      <p
        className={cn(styles.bio, {
          [styles.notShortMode]: !isShortMode,
        })}
        ref={bioRef}
      >
        {user.bio}
      </p>
      {hasShortMode &&
        (isShortMode ? (
          <button
            onClick={() => setShortMode(false)}
            className={styles.readmore}
          >
            続きを読む
          </button>
        ) : (
          <button
            onClick={() => {
              setShortMode(true);
              window.scrollTo(0, 0);
            }}
            className={styles.readmore}
          >
            一部を表示
          </button>
        ))}
    </div>
  );
};

export default BioSection;
