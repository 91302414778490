import React, { useCallback } from 'react';
import cn from 'classnames';
import axios from 'axios';
import useStore from 'store/timeline';
import { User } from 'common/interfaces/api';
import BadgeIcon from 'components/common/BadgeIcon';
import Button from 'components/common/Button';
import ProgressIcon from 'components/common/ProgressIcon';
import styles from '../Profile.module.scss';
import useGlobalStore from 'store/global';

const RatingInfoSection = ({
  user,
  isMobileScreen,
  browserWidth,
}: {
  user: User;
  isMobileScreen: boolean;
  browserWidth: number;
}): JSX.Element => {
  const currentUser = useStore((state) => state.currentUser);
  const refetchProfile = useStore((state) => state.refetchProfile);
  const showProfileHelpDialog = useGlobalStore(
    (state) => state.showProfileHelpDialog
  );
  const updateUserSettings = useCallback(async () => {
    try {
      await axios.patch('/users/settings', { rating_system: true });
      refetchProfile();
    } catch (e) {
      console.error('Error updating user settings!');
      console.error(e);
    }
  }, [refetchProfile]);
  if (!user) return;

  const isCurrentUser = user?.username === currentUser?.username;
  return (
    <>
      {user.rating_system_is_on ? (
        <div className={styles.statsGroup}>
          <div className={styles.stats}>
            <div className={styles.statsTop}>
              <div className={styles.statsLeft}>
                <ProgressIcon
                  icon="hearts-stat-2"
                  current={user.interaction_rate}
                  total={100}
                  secondColor="#FAC8D2"
                  strokeColor="#FF6074"
                  zoom={1.2}
                />
              </div>
              <div className={styles.statsRight}>
                <div className={styles.statsNum}>{user.interaction_rate}%</div>
              </div>
            </div>
            <div className={styles.statsBottom}>インタレスト</div>
          </div>
          <div className={styles.stats}>
            <div className={styles.statsTop}>
              <div className={styles.statsLeft}>
                <ProgressIcon
                  icon="share-on"
                  current={user.engagement_rate}
                  total={100}
                  secondColor="#BEE2FA"
                  strokeColor="#70C3F2"
                  zoom={1.2}
                />
              </div>
              <div className={styles.statsRight}>
                <div className={styles.statsNum}>{user.engagement_rate}%</div>
              </div>
            </div>
            <div className={styles.statsBottom}>エンゲージ</div>
          </div>
          <div className={styles.stats}>
            <div className={styles.statsTop}>
              <div className={styles.statsLeft}>
                <ProgressIcon
                  icon="followers-stat-2"
                  current={100}
                  total={100}
                  strokeColor="#6575DD"
                  secondColor="#818CEE"
                  zoom={1.2}
                />
              </div>
              <div className={styles.statsRight}>
                <div className={styles.statsNum}>{user.follow_count}</div>
              </div>
            </div>
            <div className={styles.statsBottom}>フォロワー</div>
          </div>
        </div>
      ) : currentUser && isCurrentUser ? (
        <div className={cn(styles.statsGroup, styles.statsGroupCtrl)}>
          <Button
            text="バッジレベルをONにする"
            blackAndWhite
            fadingStyle
            fullWidth
            bigGuyStyle
            onClick={() => updateUserSettings()}
          />
        </div>
      ) : (
        <div className={styles.meterDisableLabel}>
          バッジレベルの設定が{isMobileScreen && <br />}OFFになっています
        </div>
      )}
      {user.rating_system_is_on && user.badge && (
        <div className={styles.badgeGroup}>
          <div className={styles.badge}>
            <BadgeIcon
              badge={!user.is_advertiser ? user.badge : 'advertiser'}
              width={isMobileScreen ? browserWidth / 4 : 86}
              height={isMobileScreen ? browserWidth / 4 : 86}
            />
          </div>
          <div
            className={styles.help}
            onClick={() => showProfileHelpDialog(true)}
          >
            ?
            {!isMobileScreen && (
              <span className={styles.tooltip}>バッジシステムについて</span>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default RatingInfoSection;
