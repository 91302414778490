import Dialog from '@material-ui/core/Dialog';
import Icon from 'components/common/Icon';
import { Language, User } from 'common/interfaces/api';
import RoundIcon from 'components/common/RoundIcon';
import BigButton from 'components/common/BigButton';
import useGlobalStore from 'store/global';
import useStore from 'store/timeline';
import LinkButton from 'components/common/LinkButton';
import styles from './SubscribingStatusModal.module.scss';
import { timeUntil } from 'common/utils';
import { isAbleToUpgradePlan } from 'common/utils/user';
import Button from 'components/common/Button';
import { pricePackagePeriod, WebPricePackageRecurring } from 'service/payment';
import { useRouter } from 'next/router';
import {
  ableToShowSubscribingStatusModal,
  getPrimePlusPackageStatus,
  isPayingPlus,
  isPayingPrimeSub,
} from 'common/utils/payment';

export type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  creator: User;
};

export default function SubscribingStatusModal({
  open,
  setOpen,
  creator,
}: Props): JSX.Element {
  const showPaymentDialog = useGlobalStore((state) => state.showPaymentDialog);

  const handlePaymentClick = () => {
    showPaymentDialog({ creator });
    setOpen(false);
  };
  if (!ableToShowSubscribingStatusModal(creator)) return <></>;
  const primePlusPackStatus = getPrimePlusPackageStatus(creator);
  const resubscriptionButtonText =
    creator.creator_subscription?.status === 'canceled'
      ? 'プライム登録を再開する'
      : primePlusPackStatus?.status === 'canceled'
      ? 'プライムPlusの登録を再開する'
      : '';
  const canUpGradePlan = isAbleToUpgradePlan(creator);
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="lg"
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <div className={styles.dialogWrapper}>
        <div className={styles.closeBtn}>
          <Icon
            name="close"
            width={28}
            height={28}
            onClick={() => setOpen(false)}
          />
        </div>
        <div className={styles.descriptionSection}>
          <div className={styles.avatar}>
            <RoundIcon user={creator} width={62} height={62} hasRing />
          </div>
          <div className={styles.header}>
            <p>
              {creator.name}さんの
              {'\n'}
              プライム登録・プライムPlusの登録状況
            </p>
          </div>
          <PrimeSubStatus creator={creator} />
          <PrimePlusStatus creator={creator} />
          <div className={styles.actionsGroup}>
            {creator.is_open_for_subscription &&
              (resubscriptionButtonText ? (
                <BigButton
                  text={resubscriptionButtonText}
                  fadeColorWhenOn
                  onClick={handlePaymentClick}
                  fullWidth
                />
              ) : (
                canUpGradePlan && (
                  <Button
                    text="割引プランを変更"
                    onClick={() => {
                      showPaymentDialog({
                        creator: creator,
                        initPackageRecurring: WebPricePackageRecurring.Year,
                      });
                      setOpen(false);
                    }}
                  />
                )
              ))}
            <CancelButtons creator={creator} setOpen={setOpen} />
          </div>
        </div>
      </div>
    </Dialog>
  );
}

const PrimeSubStatus = ({ creator }: { creator: User }) => {
  if (!creator.creator_subscription?.status) return <></>;
  if (creator.creator_subscription?.status === 'canceled') {
    return (
      <div className={styles.subHeader}>
        <span className={styles.highlight}>
          プライム登録残り
          {timeUntil(new Date(creator.subscription_ended_at), Language.Ja)}
        </span>
        {'\n'}
        {creator.name}さんの
        {'\n'}
        プライム投稿を無制限で閲覧できます。
        {'\n'}
      </div>
    );
  }
  if (
    creator.creator_subscription?.status === 'trialing' ||
    creator.creator_subscription?.status === 'active'
  ) {
    return (
      <div className={styles.subHeader}>
        {`${pricePackagePeriod(
          creator.creator_subscription?.current_plan
        )}プラン`}
        {creator.creator_subscription?.status === 'trialing'
          ? 'のプライム登録を無料体験中'
          : 'をプライム登録中'}
      </div>
    );
  }
  return <></>;
};

const PrimePlusStatus = ({ creator }: { creator: User }) => {
  const primePlusPackStatus = getPrimePlusPackageStatus(creator);
  if (!primePlusPackStatus) return <></>;
  switch (primePlusPackStatus?.status) {
    case 'active':
      return <div className={styles.subHeader}>プライムPlusを登録中</div>;
    case 'trialing':
      return <div className={styles.subHeader}>プライムPlusを無料体験中</div>;
    case 'canceled':
      if (primePlusPackStatus.ended_at) {
        return (
          <div className={styles.subHeader}>
            <span className={styles.highlight}>
              プライムPlus残り
              {timeUntil(new Date(primePlusPackStatus.ended_at), Language.Ja)}
            </span>
            {'\n'}
            {creator.name}さんの
            {'\n'}
            プライムPlus投稿を無制限で閲覧できます。
          </div>
        );
      } else return <></>;
    default:
      return <></>;
  }
};

const CancelButtons = ({
  creator,
  setOpen,
}: {
  creator: User;
  setOpen: (open: boolean) => void;
}) => {
  const router = useRouter();
  const setSnackbarMessage = useGlobalStore(
    (state) => state.setSnackbarMessage
  );
  const isPayingPrimePackage = isPayingPrimeSub(creator);
  const primePlusPackageStatus = getPrimePlusPackageStatus(creator);
  const isPayingPlusPackage = isPayingPlus(creator);
  const currentUser = useStore((state) => state.currentUser);

  return (
    <>
      {isPayingPrimePackage && (
        <LinkButton
          text="プライム登録を解除する"
          type="normal"
          onClick={() => {
            if (creator.creator_subscription?.platform === 'android_iap') {
              setSnackbarMessage({
                type: 'error',
                text: 'Androidアプリでプライム登録を行っています。Androidアプリからプライム登録を解除してください。',
                autoHideDuration: 10000,
              });
              return;
            } else if (creator.creator_subscription?.platform === 'ios_iap') {
              setSnackbarMessage({
                type: 'error',
                text: 'iOSアプリでプライム登録を行っています。iOSアプリからプライム登録を解除してください。',
                autoHideDuration: 10000,
              });
              return;
            }
            if (isPayingPlusPackage) {
              setSnackbarMessage({
                type: 'error',
                text: 'プライムPlusを登録中のため、プライム登録を解除できません。',
                autoHideDuration: 10000,
              });
              return;
            }
            setOpen(false);
            void router.push(
              `/account/prime_subscription/${creator.username}/cancellation?pid=${currentUser?.id}`
            );
          }}
        />
      )}
      {isPayingPlusPackage && (
        <LinkButton
          text="プライムPlusを解除する"
          type="normal"
          onClick={() => {
            if (primePlusPackageStatus?.platform === 'android_iap') {
              setSnackbarMessage({
                type: 'error',
                text: 'Androidアプリでプライム登録を行っています。Androidアプリからプライム登録を解除してください。',
                autoHideDuration: 10000,
              });
              return;
            } else if (primePlusPackageStatus?.platform === 'ios_iap') {
              setSnackbarMessage({
                type: 'error',
                text: 'iOSアプリでプライム登録を行っています。iOSアプリからプライム登録を解除してください。',
                autoHideDuration: 10000,
              });
              return;
            }
            setOpen(false);
            void router.push(
              `/account/prime_plus_subscription/${creator.username}/cancellation?pid=${currentUser?.id}`
            );
          }}
        />
      )}
    </>
  );
};
