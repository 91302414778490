import React, { useEffect, useState } from 'react';
import axios from 'axios';
import useStore from 'store/timeline';
import useGlobalStore from 'store/global';
import { User, Language } from 'common/interfaces/api';
import BigButton from 'components/common/BigButton';
import { timeSince } from 'common/utils';
import styles from './FollowButtonsGroup.module.scss';
import ThreeDotsMenu from '../ThreeDotsMenu';
import { isAbleToUpgradePlan } from 'common/utils/user';
import { WebPricePackageRecurring, getPlusPackage } from 'service/payment';
import { track } from '@amplitude/analytics-browser';
import {
  isPayingPlus,
  isRenewPrimeSubStatus,
  isSubbedPrimePlusBefore,
  isSubbingPrimeByCoin,
} from 'common/utils/payment';

const FollowSubscriptionButtons = ({ user }: { user: User }): JSX.Element => {
  const showPaymentDialog = useGlobalStore((state) => state.showPaymentDialog);
  const showLogin = useGlobalStore((state) => state.showLogin);
  const currentUser = useStore((state) => state.currentUser);
  const isCurrentUser = user?.username === currentUser?.username;
  const isAbleToSub = user?.is_creator && user?.is_open_for_subscription;
  const [isFollowed, setIsFollowed] = useState(user?.is_followed);
  const canUpGradePlan = isAbleToUpgradePlan(user);
  // having prime sub benefits
  const isNotSubbingPrime =
    !user?.creator_subscription?.current_plan ||
    !user?.creator_subscription?.status;
  const renewMode = isRenewPrimeSubStatus(user);
  const plusPackage = getPlusPackage(user);
  const ableToSubPrimePlus =
    !!plusPackage && !isPayingPlus(user) && !isSubbingPrimeByCoin(user);
  const showingSubButton =
    isAbleToSub &&
    (isNotSubbingPrime || renewMode || canUpGradePlan || ableToSubPrimePlus);
  const [followingNewPost, setFollowingNewPost] = useState(
    !!user?.get_notifications_type
  );
  const trackAmpEvent = (
    eventName:
      | 'View Creator Profile'
      | 'Follow Creator'
      | 'Unfollow Creator'
      | 'Turn On Creator Notification'
      | 'Turn Off Creator Notification'
      | 'Click Prime Registration'
  ) => {
    track(eventName, {
      creator_user_id: user?.id,
      creator_badge: user?.badge,
      creator_follower_number: user?.follow_count,
    });
  };

  const refetchPosts = useStore((state) => state.refetchPosts);
  const refetchCurrentProfile = useStore(
    (state) => state.refetchCurrentProfile
  );

  useEffect(() => {
    setIsFollowed(user?.is_followed);
  }, [user?.is_followed]);

  useEffect(() => {
    setFollowingNewPost(!!user?.get_notifications_type);
  }, [user?.get_notifications_type]);

  if (!user) return <></>;

  const handleFollowClick = async (userId: number, isFollowed: boolean) => {
    if (currentUser) {
      const action = isFollowed ? 'unfollow' : 'follow';
      trackAmpEvent(
        action === 'follow' ? 'Follow Creator' : 'Unfollow Creator'
      );
      if (isFollowed) {
        setIsFollowed(false);
      } else {
        setIsFollowed(true);
      }
      await axios.post(`/users/follows`, {
        user_id: userId,
        action: action,
      });
      refetchPosts();
      refetchCurrentProfile();
    } else {
      showLogin({});
    }
  };

  const handleRegistryNotification = async () => {
    const newType = followingNewPost ? '' : 'post';
    if (newType === 'post') {
      setFollowingNewPost(true);
      trackAmpEvent('Turn On Creator Notification');
    } else {
      setFollowingNewPost(false);
      trackAmpEvent('Turn Off Creator Notification');
    }
    await axios.post(`/profiles/followership_notification`, {
      followee_name: user.username,
      notification_type: newType,
    });
    refetchCurrentProfile();
  };

  const handlePaymentClick = (withInitPackage?: boolean) => {
    trackAmpEvent('Click Prime Registration');
    if (currentUser) {
      showPaymentDialog({
        creator: user,
        ...(withInitPackage
          ? { initPackageRecurring: WebPricePackageRecurring.Year }
          : {}),
      });
    } else {
      showLogin({});
    }
  };

  const subScriptionButton = () => {
    if (!showingSubButton) return <></>;
    let btnText = '';
    let subText = '';
    let withInitPackage = false;
    let btnColor = '';
    if (renewMode || isNotSubbingPrime) {
      btnText = user.is_subscribed_before
        ? `プライム登録を再開`
        : `プライム登録を無料体験`;
      subText = user.latest_paid_post_published_at
        ? latestPaidPostTimeStr(user.latest_paid_post_published_at)
        : ' ';
    } else if (ableToSubPrimePlus) {
      const isSubbedPlusBefore = isSubbedPrimePlusBefore(user);
      btnText = isSubbedPlusBefore
        ? 'プライムPlusを再開'
        : 'プライムPlusを2週間無料体験';
      subText = user.latest_plus_paid_post_published_at
        ? latestPaidPlusPostTimeStr(user.latest_plus_paid_post_published_at)
        : ' ';
      btnColor = 'lightRed';
    } else if (canUpGradePlan) {
      btnText = `新しい割引プラン`;
      subText = ' ';
      btnColor = 'pink';
      withInitPackage = true;
    }
    return (
      <div
        style={{ width: 'calc(64% - 60px)', marginRight: '10px' }}
        className={styles.subButton}
      >
        <BigButton
          text={btnText}
          onClick={() => handlePaymentClick(withInitPackage)}
          disabled={!isAbleToSub}
          fullWidth
          bigGuyStyle
          subText={subText}
          colorName={btnColor}
        />
      </div>
    );
  };
  return (
    <div className={styles.followSubscriptionButtons}>
      {(!currentUser || !isCurrentUser) && (
        <div className={styles.followerShipCtrl}>
          <div
            style={{
              width: showingSubButton ? '36%' : 'calc(100% - 60px)',
              marginRight: '10px',
            }}
            className={styles.followGroup}
          >
            <div
              style={
                isFollowed
                  ? { width: '80px', paddingRight: '10px' }
                  : { width: '100%' }
              }
            >
              <BigButton
                text="フォローする"
                onText=""
                hasOn
                icon="follow"
                fadeColorWhenOn
                isOn={isFollowed}
                alternateColor
                bigGuyStyle
                onClick={() => handleFollowClick(user.id, isFollowed)}
                subText={' '}
              />
            </div>
            {isFollowed && (
              <div style={{ width: 'calc(100% - 75px)' }}>
                <BigButton
                  text="通知ON"
                  onText="通知OFF"
                  hasOn
                  icon="bell-follow-1"
                  fadeColorWhenOn
                  isOn={followingNewPost}
                  darkOrange
                  fullWidth
                  bigGuyStyle
                  subText={' '}
                  onClick={() => handleRegistryNotification()}
                />
              </div>
            )}
          </div>
          {showingSubButton && subScriptionButton()}
          <div style={{ width: '60px', flexShrink: 0 }}>
            <ThreeDotsMenu user={user} />
          </div>
        </div>
      )}
    </div>
  );
};

const latestPaidPostTimeStr = (date: string) => {
  if (!date) return ' ';
  const startDate = new Date(date);
  if (startDate.toString() === 'Invalid Date') return '';
  const days = Math.floor(
    (new Date().getTime() - startDate.getTime()) / 86400000
  );
  if (days === 0)
    return `最新のプライム投稿は${timeSince(startDate, Language.Ja)}`;
  return `最新のプライム投稿は${days}日前`;
};

const latestPaidPlusPostTimeStr = (date: string) => {
  if (!date) return ' ';
  const startDate = new Date(date);
  if (startDate.toString() === 'Invalid Date') return '';
  const days = Math.floor(
    (new Date().getTime() - startDate.getTime()) / 86400000
  );
  if (days === 0)
    return `最新のプライムPlus投稿は${timeSince(startDate, Language.Ja)}`;
  return `最新のプライムPlus投稿は${days}日前`;
};

export default FollowSubscriptionButtons;
