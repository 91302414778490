import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import axios from 'axios';
import Link from 'next/link';
import { useMutation } from 'react-query';
import Image from 'next/image';
import useStore from 'store/timeline';
import useGlobalStore from 'store/global';
import RoundIcon from 'components/common/RoundIcon';
import Icon from 'components/common/Icon';
import { PostMediaResult, User } from 'common/interfaces/api';
import FileSelect from 'components/common/FileSelect';
import ImageEditor from 'components/common/ImageEditor';
import PopperWrapper from 'components/common/PopperWrapper';
import EmotionSelector from 'components/common/EmotionSelector';
import { useWindowSize, useMediaQuery } from 'common/utils';
import styles from './Profile.module.scss';
import BioSection from './BioSection';
import RatingInfoSection from './RatingInfoSection';
import FollowSubcriptionButtons from './FollowButtonsGroup';
import { isIzanaviMember, isMembershipAccount } from 'common/utils/membership';
import { ScreenName } from 'common/utils/pp_tracking';
import MembershipVipIcon from 'components/common/MembershipVipIcon';

export type Props = {
  user: User;
  hideBorder?: boolean;
  hideEditBtn?: boolean;
  profilePicEditable?: boolean;
};

export default function Profile({
  user,
  hideBorder = false,
  hideEditBtn = false,
  profilePicEditable = false,
}: Props): JSX.Element {
  const [openImageEditor, setOpenImageEditor] = useState(false);
  const [mediaUrl, setMediaUrl] = useState<string>();
  const refetchProfile = useStore((state) => state.refetchProfile);
  // Help dialog
  const setShowMembershipPayDialog = useGlobalStore(
    (state) => state.setShowMembershipPayDialog
  );
  const showLogin = useGlobalStore((state) => state.showLogin);
  const currentUser = useStore((state) => state.currentUser);
  const isCurrentUser = user?.username === currentUser?.username;
  const isMobileScreen = useMediaQuery('(max-width: 767px)');
  const { browserWidth } = useWindowSize();

  const handleFileSelect = useCallback((file: File) => {
    setMediaUrl(URL.createObjectURL(file));
    setOpenImageEditor(true);
  }, []);

  // Media upload
  const setSnackbarMessage = useGlobalStore(
    (state) => state.setSnackbarMessage
  );
  const { mutate: updateProfilePic } = useMutation(
    (url: string) => {
      return axios.post(`/profiles/avatars`, {
        avatar: url,
      });
    },
    {
      onSuccess: () => {
        refetchProfile();
      },
      onError: () => {
        setSnackbarMessage({
          type: 'error',
          text: '画像の更新が失敗されました',
        });
      },
    }
  );

  const { mutate: uploadProfilePic } = useMutation(
    async (file: File) => {
      // TODO: validate size of content
      const formData = new FormData();
      formData.append('file', file);
      return (
        await axios.post(`/posts/media`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      ).data as PostMediaResult;
    },
    {
      onSuccess: (data) => {
        updateProfilePic(data?.data?.data.url);
      },
      onError: () => {
        setSnackbarMessage({
          type: 'error',
          text: '画像の更新が失敗されました',
        });
      },
    }
  );

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const emotionEl = (
    <div className={styles.emotion}>
      <Image
        src={`/images/icons/emotion-${user?.emotion || 'add'}.svg`}
        width={52}
        height={52}
        alt="emotion"
      />
    </div>
  );

  return user ? (
    <div
      className={cn(styles.profileWrapper, {
        [styles.profileWrapperBorderNone]: hideBorder,
      })}
    >
      <div
        className={cn(styles.firstLine, {
          [styles.infoPage]: profilePicEditable,
        })}
      >
        {profilePicEditable ? (
          <div className={styles.uploadImage}>
            <FileSelect
              onFileSelect={handleFileSelect}
              id="profile-picture-select"
              accept=".jpeg,.jpg,.png"
            >
              <div className={styles.profilePicGroup}>
                <RoundIcon
                  user={user}
                  width={isMobileScreen ? browserWidth / 5 : 86}
                  height={isMobileScreen ? browserWidth / 5 : 86}
                  hasRing
                  showLiveFlag
                />
                <PopperWrapper
                  id="emotion-selector-dashboard"
                  buttonEl={emotionEl}
                  contentEl={<EmotionSelector />}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  hover
                />
                <div className={styles.profilePicOverlay}>
                  <div className={styles.camera}>
                    <Icon name="camera" width={32} height={32} />
                  </div>
                </div>
              </div>
            </FileSelect>
            <label
              htmlFor="profile-picture-select"
              className={styles.editProfilePicLabel}
            >
              プロフィール画像を編集
            </label>
            <ImageEditor
              open={openImageEditor}
              setOpen={setOpenImageEditor}
              mediaUrl={mediaUrl}
              onEditComplete={uploadProfilePic}
              fixedRatio={1}
            />
          </div>
        ) : (
          <RoundIcon
            user={user}
            width={isMobileScreen ? browserWidth / 5 : 86}
            height={isMobileScreen ? browserWidth / 5 : 86}
            emotion={user.emotion}
            showLiveFlag
            hasRing
          />
        )}
        <RatingInfoSection
          user={user}
          isMobileScreen={isMobileScreen}
          browserWidth={browserWidth}
        />
      </div>
      <div className={styles.userNameGroup}>
        <h1 className={styles.userName}>{user.name}</h1>
        {(isMembershipAccount(user) || isIzanaviMember(user)) && (
          <span className={styles.userNameIcon}>
            <MembershipVipIcon
              packageName={user.membership?.membership_package?.package_name}
              width={66}
              height={35}
              isIzanavi={isIzanaviMember(user)}
              onClick={() => {
                if (!currentUser) {
                  showLogin({});
                  return;
                }
                setShowMembershipPayDialog({
                  open: true,
                  showMembershipStatus: false,
                  screen: ScreenName.Profile,
                });
              }}
            />
          </span>
        )}
        {user.is_advertiser && (
          <span className={styles.userNameIcon}>
            <Icon name="done" width={20} height={20} />
          </span>
        )}
        <span className={styles.country}>
          <Image src={'/images/icons/location.svg'} width={32} height={32} />
          <span>{user.country.name}</span>
          {currentUser && isCurrentUser && !hideEditBtn && (
            <Link href="/account?tab=info">
              <a style={{ marginTop: '4px' }}>
                <Image src={'/images/icons/edit.svg'} width={24} height={24} />
              </a>
            </Link>
          )}
        </span>
      </div>
      {user.job && <div className={styles.userJob}>{user.job}</div>}
      <div className={styles.extraContainer}>
        <BioSection user={user} />
        <FollowSubcriptionButtons user={user} />
      </div>
    </div>
  ) : (
    <div></div>
  );
}
