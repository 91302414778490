import { Language, User } from 'common/interfaces/api';
import {
  OptionalPackageName,
  OptionalPackageStatus,
  WebPricePackage,
  WebPricePackageRecurring,
} from 'service/payment';
import { timeUntil } from '.';

export const CHECK_3DS_CODE = '3DS-authentication-complete';
// having plus benefits
export const isHavingPlusBenefits = (creator: User) => {
  if (!creator) return false;
  const primePlusPackStatus = creator?.option_creator_subscriptions?.find(
    (option) => option.optional_type === OptionalPackageName.Plus
  );
  if (!primePlusPackStatus) return false;
  if (
    primePlusPackStatus?.status === 'active' ||
    primePlusPackStatus?.status === 'trialing' ||
    primePlusPackStatus?.status === 'canceled'
  )
    return true;
  if (primePlusPackStatus.ended_at)
    return (
      primePlusPackStatus.ended_at &&
      new Date(primePlusPackStatus.ended_at) > new Date()
    );
};

export const getPrimePlusPackageStatus = (creator: User) => {
  if (!creator) return null;
  return creator?.option_creator_subscriptions?.find(
    (option) => option.optional_type === OptionalPackageName.Plus
  );
};

// paying for plus benefits
export const isPayingPlus = (creator: User) => {
  if (!creator) return false;
  const primePlusPackStatus = creator?.option_creator_subscriptions?.find(
    (option) => option.optional_type === OptionalPackageName.Plus
  );
  if (!primePlusPackStatus) return false;
  return (
    primePlusPackStatus?.status === 'active' ||
    primePlusPackStatus?.status === 'trialing' ||
    primePlusPackStatus?.status === 'unpaid'
  );
};

export const isSubbingPrimeByCoin = (creator: User) => {
  if (!creator) return false;
  return ['web_coin', 'android_coin', 'ios_coin'].includes(
    creator?.creator_subscription?.platform
  );
};

export const isSubbedPrimePlusBefore = (creator: User) => {
  if (!creator) return false;
  const primePlusPackStatus = creator?.option_creator_subscriptions?.find(
    (option) => option.optional_type === OptionalPackageName.Plus
  );
  if (!primePlusPackStatus) return false;
  return !!primePlusPackStatus?.is_subscribed_before;
};

export const ableToShowSubscribingStatusModal = (creator: User) => {
  if (!creator) return false;
  return (
    isHavingPlusBenefits(creator) ||
    isRenewPrimeSubStatus(creator) ||
    isPayingPrimeSub(creator)
  );
};

export const plusPackageStatusText = (
  primePlusPackStatus: OptionalPackageStatus
) => {
  if (!primePlusPackStatus) return '';
  switch (primePlusPackStatus?.status) {
    case 'active':
      return '登録中';
    case 'trialing':
      return '無料体験中';
    case 'canceled':
      return `残り ${timeUntil(
        new Date(primePlusPackStatus.ended_at),
        Language.Ja
      )}`;
    default:
      return '';
  }
};

export const isRenewPrimeSubStatus = (creator: User) => {
  if (!creator) return false;
  return creator?.creator_subscription?.status === 'canceled';
};

export const isPayingPrimeSub = (creator: User) => {
  if (!creator) return false;
  return (
    creator?.creator_subscription?.status === 'active' ||
    creator?.creator_subscription?.status === 'trialing' ||
    creator?.creator_subscription?.status === 'unpaid'
  );
};

export const isAbleToCreatorNewPrimeSub = (creator: User) => {
  return (
    !creator?.creator_subscription?.status || isRenewPrimeSubStatus(creator)
  );
};

export const isHavingPrimeBenefits = (creator: User) => {
  if (!creator) return false;
  return (
    creator?.creator_subscription?.status === 'active' ||
    creator?.creator_subscription?.status === 'trialing' ||
    creator?.creator_subscription?.status === 'canceled'
  );
};

export const showingPrimeSubPrice = (
  pack: WebPricePackage,
  discountByMembershipWebAmount: number,
  currentPaymentBy: 'credit' | 'coin'
) => {
  if (
    discountByMembershipWebAmount > 0 &&
    pack?.recurring === WebPricePackageRecurring.Month &&
    currentPaymentBy === 'coin'
  ) {
    return discountByMembershipWebAmount;
  }
  if (
    pack?.recurring === WebPricePackageRecurring.Month &&
    currentPaymentBy === 'coin'
  ) {
    return pack?.original_web_amount;
  }
  return pack?.discounted_web_amount;
};
